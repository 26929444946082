import React from "react";
import { graphql } from "gatsby";
import { concatClassNames as cn } from "@sys42/utils";

import {
  featureOverviewFeatures,
  advertedPoolSize,
} from "@userbrain/website-ui";

import {
  twoColumnsInviteYourOwn,
  testimonialPascal,
  testimonialJamie,
  testimonialAaron,
  testimonialPaul,
} from "../helpers";

import Layout from "../layouts/layout";
import { Brands } from "../components/Brands";
import { ContentCentered } from "../components/ContentCentered";
import { Examples } from "../components/Examples";
import { FeatureOverview } from "../components/FeatureOverview";
import { GetStartedBox } from "../components/GetStartedBox";
import { HomeTeaser } from "../components/HomeTeaser";
import { Testimonials } from "../components/Testimonials";
import { ThreeColumnsDefault } from "../components/ThreeColumnsDefault";
import { TwoColumns } from "../components/TwoColumns";
import { WavySeparator } from "../components/WavySeparator";

import * as helperStyles from "./helpers.module.css";
import * as styles from "./styles/index.module.css";

import imgShareInsights from "./../images/shareInsights.jpg";
import imgTaskTypes from "./../images/taskTypes.jpg";
import imgTesterDemographics from "./../images/testerDemographics.jpg";
import imgSessionsNew from "./../images/sessionsNew.jpg";
import { StaticImage } from "gatsby-plugin-image";

export default function Index(props) {
  const { data } = props;

  const examples = data.allStrapiExample.edges.map((edge) => edge.node);

  return (
    <Layout notTranslated={true}>
      <HomeTeaser />

      <div className={helperStyles.containerColor}>
        <Brands className={helperStyles.spacingLg} />
      </div>

      <div className={cn(helperStyles.container, styles.containerHowItWorks)}>
        <ThreeColumnsDefault
          alternativeStyle={true}
          title={
            <>
              <span className={helperStyles.h2Serif}>
                How user testing with
              </span>{" "}
              Userbrain <span className={helperStyles.h2Serif}>works:</span>
            </>
          }
          titleWidth={"13em"}
          col1Image={imgTaskTypes}
          col1Alt={"Task types"}
          col1Heading={"1. Set up your test"}
          col1Text={
            "If you can link to it, you can test it: websites, apps, prototypes – you name it. Use our templates or create your own test with tasks, questions, ratings, and more."
          }
          col2Image={imgTesterDemographics}
          col2Alt={"Countries"}
          col2Heading={"2. Recruit your testers"}
          col2Text={`Get people from our ${advertedPoolSize} pool of qualified testers and target their demographics and screen for prior experiences, habits or preferences. You can also invite your own users.`}
          col3Image={imgShareInsights}
          col3Alt={"Copy shared clip link"}
          col3Heading={"3. Share your insights"}
          col3Text={
            "Speed up your test analysis with automatic transcripts and AI insights. Get the right information to the right people with automated test reports and clips."
          }
        />
      </div>

      <WavySeparator />

      <div className={helperStyles.container}>
        <ContentCentered
          className={helperStyles.spacingLg}
          title={
            "The easy and affordable user testing tool for websites, apps & prototypes"
          }
          titleWidth={"17em"}
          imageSrc={imgSessionsNew}
          imageAlt={"Screenshot of Userbrain: New sessions"}
          textContent={
            "Userbrain shows you what’s working for your product — and what’s not. A simple tool that lets you create a user test in minutes and get results in just a few hours. Whether you’re a UX designer, researcher, or manager, with Userbrain you’re bound to build products people love to use."
          }
          textContentAlignLeft={true}
        />

        <Examples
          className={styles.userbrainExamples}
          title={"Check out some templates:"}
          examples={examples}
          textContent={
            "Explore a wide range of template tasks and questions to give you an idea  about how to get the most valuable insights from your user tests."
          }
        />
      </div>

      <div
        className={cn(
          helperStyles.container,
          helperStyles.container_featureOverview
        )}
      >
        <FeatureOverview
          className={styles.features}
          title={"Userbrain features for easy, unmoderated remote user testing"}
          titleWidth={"17em"}
          features={featureOverviewFeatures}
        />
      </div>

      <div className={styles.containerDefault}>
        <TwoColumns
          className={styles.watchUsers}
          inverted={true}
          heading={
            "Watch users interact with your product and hear their thoughts."
          }
          content={
            "Watch real people with different personalities, locations, and device types interact with your product in their normal surroundings."
          }
          linkTo={"/features/tester-pool"}
          linkText={"More about Userbrain tester pool"}
          image={(className) => (
            <div className={cn(className, styles.imageQualityAssuredTesters)}>
              <StaticImage
                src={"./../images/qualityAssuredTesters.jpg"}
                alt={`More than ${advertedPoolSize} Quality Assured Testers`}
              />
              <svg viewBox="0 0 50 20">
                {/* inherit font and color and kerning/letter spacing and font-weight from the HTML node that contains the SVG*/}
                <text
                  x="25"
                  y="16"
                  textAnchor="middle"
                  fontSize="16"
                  fill="currentColor"
                  letterSpacing={-1}
                  fontWeight="600"
                  fontKerning="auto"
                >
                  {advertedPoolSize}
                </text>
              </svg>
            </div>
          )}
        />
      </div>

      <div className={styles.containerDefault}>
        <TwoColumns
          className={styles.inviteYourOwnTesters}
          {...twoColumnsInviteYourOwn}
        />
        <GetStartedBox className={styles.getStartedBox} />
        <Testimonials
          className={styles.testimonials}
          testimonials={[
            testimonialPascal,
            testimonialPaul,
            testimonialJamie,
            testimonialAaron,
          ]}
        />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query examplesForHome {
    allStrapiExample(
      filter: { online: { eq: true }, strapi_id: { in: [4, 3, 9, 5] } }
    ) {
      edges {
        node {
          id
          url
          title
          type
          device
          companyName
          cardTitleWhite
          taskTypes {
            title
            url
          }
          categories {
            title
            url
          }
          cardColor
          logo {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
